<script setup lang="ts">
import { QuestionCircleIcon } from '@cfgtech/icons'
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import SanitizedHTML from '~/components/base/SanitizedHTML.vue'
import type { SbVerticalBenefitItemStoryblok } from '~/types/storyblok'

const props = withDefaults(defineProps<{
  blok: SbVerticalBenefitItemStoryblok
  isLastElement?: boolean
}>(), {
  isLastElement: false,
})

const renderedTitle = computed(() => renderRichText(props.blok.title))

const renderedDescription = computed(() => renderRichText(props.blok.description))

const isBigVariantShown = computed(() => Boolean(renderedTitle.value))

const iconSizeClass = computed(() => {
  if (isBigVariantShown.value) {
    return 'size-[47px]'
  }

  return 'size-[24px]'
})
</script>

<template>
  <li>
    <div
      v-editable="blok"
      class="flex justify-start gap-x-3"
      :class="{
        'py-2.5': isBigVariantShown,
        'py-2': !isBigVariantShown,
      }"
    >
      <div
        class="mt-0.5 shrink-0 border border-transparent"
        :class="iconSizeClass"
      >
        <LazyNuxtPicture
          v-if="blok.icon?.filename"
          :alt="blok.icon.alt || 'icon'"
          :height="isBigVariantShown ? 47 : 24"
          :img-attrs="{
            class: 'size-full object-contain',
          }"
          preload
          provider="storyblok"
          :src="blok.icon?.filename"
          :width="isBigVariantShown ? 47 : 24"
        />

        <QuestionCircleIcon
          v-else
          class="text-grey-300"
          :class="iconSizeClass"
        />
      </div>

      <div
        class="flex flex-col gap-y-4"
        :class="{
          'mt-1.5': isBigVariantShown,
        }"
      >
        <SanitizedHTML
          v-if="renderedTitle"
          by-tag-name="h3"
          :html="renderedTitle"
        >
          <template #default="{ html }">
            <CfgTypography
              class="!font-highlighted"
              :html="html"
              :size="CfgTypographySizes.lg"
              tag-name="h3"
            />
          </template>
        </SanitizedHTML>

        <CfgTypography
          v-if="blok.description"
          :html="renderedDescription"
          :size="CfgTypographySizes.md"
          tag-name="div"
        />
      </div>
    </div>
  </li>
</template>
